import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import SEO from '../components/seo'
import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
import DrawerMenu from '../components/DrawerMenu'
import Cart from '../components/Cart'
import { PressClippingsList, PressContact, MediaKit } from '../components/MediaPage'

import { breakpoints } from '../utils/styles'

const PageWrap = styled.div`
  ${tw`w-full`}
`

const BodyLargeWrap = styled.div`
  ${tw`relative h-auto p-12 md:p-20 py-32 md:py-40`}
  background-color: #EDEDED;
  min-height: 80vh;

  p {
    margin-bottom: 3rem;
  }
`

const Content = styled.div`
  ${tw`mx-auto max-w-5xl font-extended`}
  font-size: 20px;
  line-height: 28px;
  @media (min-width: ${breakpoints.md}px) {
    line-height: 1.45;
    font-size: 1.75rem;
  }
`

const MediaInformation = styled.div`
  ${tw`w-full px-12 md:px-20 lg:px-16 pb-16 md:pb-24 pt-16 md:pt-12 xl:px-24 lg:py-40 lg:order-2 lg:fixed lg:right-0 lg:top-0 lg:z-30 lg:h-full bg-colekt-light-gray overflow-y-scroll text-colekt-dark-gray`}
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Title = styled.h1`
  ${tw`text-4xl mb-12 uppercase`}
`

const MediaPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        globalSettings {
          press {
            mediaKitBody
            mediaKitFile {
              title
              localFile {
                publicURL
                size
              }
            }
            pressContactTitle
            pressContactBody
            mediaKitTitle
            pressSeoDescription
            pressTitle
          }
        }
      }
      allWpPressClipping(sort: { fields: date, order: DESC }) {
        nodes {
          date
          title
          pressClippingData {
            publisher
            file {
              localFile {
                publicURL
              }
              title
            }
            url {
              target
              title
              url
            }
          }
        }
      }
    }
  `)

  const {
    mediaKitFile,
    pressContactTitle,
    pressContactBody,
    mediaKitBody,
    mediaKitTitle,
    pressSeoDescription,
    pressTitle,
  } = data.wp.globalSettings.press

  return (
    <>
      <SEO title={pressTitle} description={pressSeoDescription} />
      <Header />
      <DrawerMenu />
      <Cart />
      <PageWrap className="whiteonblack aside__padding">
        <BodyLargeWrap>
          <Content>
            <Title>{pressTitle}</Title>
            <PressClippingsList clips={data.allWpPressClipping} />
          </Content>
        </BodyLargeWrap>
        <MediaInformation className="aside__width">
          <PressContact title={pressContactTitle} body={pressContactBody} />
          <MediaKit title={mediaKitTitle} body={mediaKitBody} file={mediaKitFile} />
        </MediaInformation>
        <Footer />
      </PageWrap>
    </>
  )
}

export default MediaPage
