import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html'
import BoxIcon from '../../assets/box.svg'

// Styled components
const Wrap = styled.div`
  ${tw``}
`

const Title = styled.h1`
  ${tw`text-2xl uppercase font-extended mb-4`}
`

const Body = styled.div`
  ${tw`font-mono text-xs`}

  p {
    ${tw`mb-5`}
  }
`

const MediaFile = styled.div`
  ${tw`font-mono text-xs`}

  svg {
    max-width: 16px;
    margin-right: 0.5rem;
  }

  a {
    ${tw`flex items-center hover:opacity-50`}
  }
`

const MediaKit = (props) => {
  const { body, file, title } = props

  const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024)

  return (
    <>
      {title ? (
        <Wrap>
          <Title>{title}</Title>
          <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(body) }} />
          {file ? (
            <MediaFile>
              <a href={file.localFile.publicURL} title={file.title}>
                <BoxIcon />
                {file.title} ({bytesToMegaBytes(file.localFile.size).toFixed(3)}
                MB)
              </a>
            </MediaFile>
          ) : null}
        </Wrap>
      ) : null}
    </>
  )
}

MediaKit.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  file: PropTypes.object,
}

export default MediaKit
