import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import Moment from 'react-moment'

// Styled components
const Wrap = styled.div`
  ${tw`mb-12`}
  max-width: 850px;
`

const Metadata = styled.div`
  ${tw`flex mb-2 text-xs md:text-sm uppercase`}
  white-space: pre-wrap;

  span {
    ${tw`opacity-50`}
  }
`

const Title = styled.h2`
  ${tw`text-xl md:text-2xl font-extended hover:opacity-50`}
`

const PressClipping = ({ data }) => {
  const { date, title, pressClippingData } = data
  const { publisher, url, file } = pressClippingData

  return (
    <Wrap>
      <Metadata>
        {publisher} |{' '}
        <span>
          <Moment format="MMM 'YY">{date}</Moment>
        </span>
      </Metadata>
      <Title>
        <a href={file ? file.localFile.publicURL : url?.url} title="View press clip">
          {title}
        </a>
      </Title>
    </Wrap>
  )
}

PressClipping.propTypes = {
  data: PropTypes.object,
}

export default PressClipping
