import React from 'react'
import PropTypes from 'prop-types'
import { PressClipping } from '.'

const PressClippingsList = ({ clips }) => (
  <>
    {clips?.nodes.map((clip, key) => (
      <PressClipping data={clip} key={key} />
    ))}
  </>
)

PressClippingsList.propTypes = {
  clips: PropTypes.object,
}

export default PressClippingsList
