import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html'

// Styled components
const Wrap = styled.div`
  ${tw`mb-12`}
`

const Title = styled.h1`
  ${tw`text-2xl uppercase font-extended mb-4`}
`

const Body = styled.div`
  ${tw`font-mono text-base`}

  p {
    ${tw`mb-5`}
  }
`

const PressContact = (props) => {
  const { body, title } = props

  return (
    <>
      {title ? (
        <Wrap>
          <Title>{title}</Title>
          <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(body) }} />
        </Wrap>
      ) : null}
    </>
  )
}

PressContact.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
}

export default PressContact
